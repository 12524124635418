import React from 'react';
import { Helmet } from 'react-helmet';
import OrderSummaryItem from '../../components/order_summary_item';
import { cartStore, useCartStore } from '../../store/cart';
import { NumberFormat } from '../../config/number_utils';
import { LoadingContext } from '../../components/loading/context';
import { api } from '../../config/api';
import { useNavigate } from 'react-router-dom';
import './check-out.scss';

function CheckOut() {
    const { cart, reset } = cartStore();
    let navigate = useNavigate();
    const { discountTotal, taxes, total_taxes } = useCartStore();
    const { openLoading, closeLoading } = React.useContext(LoadingContext);
    const [isEmailForm, setIsEmailForm] = React.useState<boolean>(true);
    const [isAddressForm, setIsAddressForm] = React.useState<boolean>(false);
    const [departamentos, setDepartamentos] = React.useState<string[]>([]);
    const [checkoutData, setCheckoutData] = React.useState<any>({});

    const [formData, setFormData] = React.useState({
        correo: '',
        nombre: '',
        apellido: '',
        direccion: '',
        direccionComplementaria: '',
        departamento: '',
        ciudad: '',
        telefono: '',
        documento: '',
    });

    const [formErrorData, setFormErrorData] = React.useState<any>({
        correo: '',
        nombre: '',
        apellido: '',
        direccion: '',
        direccionComplementaria: '',
        departamento: '',
        ciudad: '',
        telefono: '',
        documento: '',
    });

    function enabled() {
        const { correo: _, direccionComplementaria: __, ...form } = formErrorData;
        return !Object.values(formErrorData).some(error => error !== '') && formData.departamento !== '';
    }

    React.useEffect(() => {
        openLoading();
        api.get('/address/departments').then(response => {
            setDepartamentos(response.data);
        }).finally(() => {
            closeLoading();
        });
    }, []);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        let error = '';
        if (name === 'correo') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                error = 'Correo electrónico inválido';
            }
        } else if (name === 'nombre') {
            if (value.length < 3) {
                error = 'Tú nombre debe tener al menos 3 caracteres';
            }
        } else if (name === 'apellido') {
            if (value.length < 3) {
                error = 'Tú apellido debe tener al menos 3 caracteres';
            }
        } else if (name === 'ciudad') {
            if (value.length < 3) {
                error = 'Tú ciudad debe tener al menos 3 caracteres';
            }
        } else if (name === 'telefono') {
            if (value.length < 10) {
                error = 'El teléfono debe tener minimo 10 caracteres';
            }
        } else if (name === 'documento') {
            if (value.length < 5) {
                error = 'El documento de identidad no es valido';
            }
        }
        setFormErrorData((prevState: any) => ({ ...prevState, [name]: error }));
        setFormData((prevState: any) => ({ ...prevState, [name]: value }));
    };

    async function generateOrder() {
        try {
            // if (checkoutData && checkoutData?.reference) {
            //     openCheckout(checkoutData);
            // } else {
            openLoading();
            setIsEmailForm(false);
            setIsAddressForm(false);
            const response = await api.post('/checkout', {
                ...formData,
                productos: cart,
            }).then(response => response.data.data).finally(() => {
                closeLoading();
            });
            setCheckoutData(response);
            openCheckout(response);
            // }
        } catch (error) {
            console.error(error);
        }
    }

    function openCheckout(data?: any) {
        const checkout = new (window as any).WidgetCheckout(data ?? checkoutData);
        checkout.open(function (result: any) {
            var transaction = result.transaction;
            navigate(transaction.redirectUrl.split(window.location.host)[1], { replace: true });
            reset();
        });
    }
    console.log(formErrorData, enabled());
    return (
        <div className="check-out">
            <Helmet>
                <title>Finaliza tu Compra - Tecno Ofertas</title>
                <meta name="description" content="Completa tu compra en Tecno Ofertas. Ingresa tus datos y finaliza tu pedido de productos tecnológicos con seguridad y facilidad." />
            </Helmet>
            <div className='container'>
                <div className={`email-form ${isEmailForm ? '' : 'active'}`}>
                    <div className='header'>
                        <h2>1. Email</h2>
                        <button onClick={() => {
                            setIsEmailForm(true);
                            setIsAddressForm(false);
                        }}>Editar</button>
                    </div>
                    <input name="correo" id="correo" className="w-full p-2 border border-gray-300 rounded mb-2" placeholder="Email" type="email" onChange={handleChange} />
                    {formErrorData.email && <span className="error-inline-text">{formErrorData.email}</span>}
                    <span>{formData.correo}</span>
                    <p>Recibirás todas las actualizaciones de tu pedido</p>
                    {!(formErrorData.correo || !formData.correo) && <button onClick={() => {
                        setIsEmailForm(false);
                        setIsAddressForm(true);
                    }}>Continuar</button>}
                </div>
                <div className={`delivery-form  ${isEmailForm ? 'disabled' : !isAddressForm && !isEmailForm ? 'active' : ''}`}>
                    <div className='title'>
                        <h2>2. Metodos de entrega</h2>
                        <button onClick={() => {
                            setIsAddressForm(true);
                        }}>Editar</button>
                    </div>
                    <div className='editable-content'>
                        <div className="delivery-option">
                            <label>Opciones de entrega</label>
                            <div>
                                <span>Envío</span>
                                <span>Gratis</span>
                            </div>
                        </div>
                        <input name="documento" id="documento" className="w-full p-2 border border-gray-300 rounded mb-2" placeholder="Documento de identidad" type="text" onChange={handleChange} />
                        <div style={{ display: 'flex', gap: 16 }}>
                            <input name="nombre" id="nombre" className="w-full p-2 border border-gray-300 rounded mb-2" placeholder="Nombre" type="text" onChange={handleChange} />
                            <input name="apellido" id="apellido" className="w-full p-2 border border-gray-300 rounded mb-2" placeholder="Apellido" type="text" onChange={handleChange} />
                        </div>
                        <input name="direccion" id="direccion" className="w-full p-2 border border-gray-300 rounded mb-2" placeholder="Dirección" type="text" onChange={handleChange} />
                        <input name="direccionComplementaria" id="direccionComplementaria" className="w-full p-2 border border-gray-300 rounded mb-2" placeholder="Casa/aparamento/oficina" type="text" onChange={handleChange} />
                        <select name="departamento" id="departamento" value={formData.departamento} onChange={handleChange} className='placeholder'>
                            <option key={'empty'} value="">{'Selecciona una departamento'}</option>
                            {departamentos.map((department) => (
                                <option key={department} value={department}>{department}</option>
                            ))}
                        </select>
                        <input name="ciudad" id="ciudad" className="w-full p-2 border border-gray-300 rounded mb-2" placeholder="Ciudad" type="text" onChange={handleChange} />
                        <input name="telefono" id="telefono" className="w-full p-2 border border-gray-300 rounded mb-2" placeholder="Teléfono" type="tel" onChange={handleChange} />
                    </div>
                    <div className='content'>
                        <label>Envío</label>
                        <div className='content--container'>
                            <div className='info'>
                                <span>
                                    {`${formData.nombre} ${formData.apellido}`}
                                </span>
                                <div className='placeholder'>
                                    <p>{formData.telefono}</p>
                                    <p>{formData.direccion}</p>
                                    <span>{formData.direccionComplementaria}</span>
                                    <p>Colombia</p>
                                </div>
                            </div>
                            <div className='shipping'>
                                <p>Tarifa</p>
                                <span>Gratis</span>
                            </div>
                        </div>
                    </div>
                    {enabled() && <button onClick={generateOrder}>Continuar</button>}
                </div>
            </div>
            <div className="order-summary">
                <h2>Resumen de la orden</h2>
                <div className="flex justify-between items-center mb-4">
                    {cart.map(value => <OrderSummaryItem item={value} key={value.name} />)}
                </div>
                <div>
                    <div className="subtotal-container">
                        <p>Subtotal</p>
                        <p>{NumberFormat.format(total_taxes)}</p>
                    </div>
                    <div className="subtotal-container">
                        <p>IVA</p>
                        <p>{NumberFormat.format(taxes)}</p>
                    </div>
                    <div className="subtotal-container">
                        <p>Envío</p>
                        <p>--</p>
                    </div>
                    <div className="total-container">
                        <p>Total</p>
                        <p>{NumberFormat.format(discountTotal)}</p>
                    </div>
                    <div className="secure-badge">
                        <span>Compra segura</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CheckOut;
import React, { FunctionComponent } from 'react';
import './cart-item.scss';
import { NumberFormat } from '../../config/number_utils';
import { cartStore } from '../../store/cart';

interface CartItemProps {
    item: any
}

const CartItem: FunctionComponent<CartItemProps> = ({ item }) => {
    const { photo_url: images, category, name, cart_property: { key, value }, price, discount, quantity, reference } = item;
    const { add, remove } = cartStore();
    return (
        <div className='cart-item'>
            <span className='brand'>Apple</span>
            <div className='cart-item--product-info'>
                <div className='image-container'>
                    <img src={images[0]} alt={'photoUrl'} />
                </div>
                <div className='info-container'>
                    <div className='category'>{category}</div>
                    <p className='name'>{name}</p>
                    <span className='color'>{key}: <span>{value}</span></span>
                </div>
                <div className='price-container'>
                    <p className="old-price line-through">
                        {NumberFormat.format(price)}
                    </p>
                    <p className="price">
                        {NumberFormat.format(price * discount)}
                    </p>
                    <div className='controls'>
                        <button onClick={() => {
                            remove(reference);
                        }}>-</button>
                        <span>{quantity}</span>
                        <button onClick={() => {
                            add(item);
                        }}>+</button>
                    </div>
                </div>
            </div>
            <div className='price-container-xs'>
                <p className="price">
                    {NumberFormat.format(price * discount)}
                </p>
                <div className='controls'>
                    <button onClick={() => {
                        remove(reference);
                    }}>-</button>
                    <span>{quantity}</span>
                    <button onClick={() => {
                        add(item);
                    }}>+</button>
                </div>
            </div>
        </div>
    );
}

export default CartItem;
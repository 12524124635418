import { Helmet } from 'react-helmet';
import { cartStore } from "../../store/cart";
import CartItem from "./../../components/cart_item";
import CartSummary from "./../../components/cart_summary";
import './cart.scss';

function Cart() {
    const { cart } = cartStore();
    return (
        <div className="cart">
            <Helmet>
                <title>Carrito de Compras - Tecno Ofertas</title>
                <meta name="description" content="Revisa tu carrito de compras en Tecno Ofertas. Asegúrate de no dejar pasar nuestras increíbles ofertas en tecnología." />
            </Helmet>
            <div className="cart--container">
                <h1 className="title">CARRO DE COMPRAS</h1>
                <div className="order-summary">
                    <div className="items-container">
                        {cart.map((value, index) => <CartItem key={`item-${index}`} item={value} />)}
                    </div>
                    {Boolean(cart?.length && cart.length > 0) && <div className="summary-container">
                        <CartSummary />
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default Cart;
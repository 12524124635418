import React, { FunctionComponent } from 'react';
import './accordion.scss';

interface AccordionItemProps {
    item: { title: string; content: string; };
    isActive: boolean;
    onSetActive: () => void;
}

const AccordionItem: FunctionComponent<AccordionItemProps> = ({ item: { title, content }, isActive, onSetActive }) => {

    return (
        <div className="accordion-item">
            <button className={`accordion-title ${isActive ? 'active' : ''}`} onClick={onSetActive}>
                <span>{title}</span>
                <i className={`fas ${isActive ? 'fa-minus' : 'fa-plus'}`}></i>
            </button>
            <div className={`accordion-content ${isActive ? 'active' : ''}`} style={{ maxHeight: isActive ? 'none' : '0px' }}>
                {content}
            </div>
        </div>
    );
};

export { AccordionItem };

interface AccordionProps {
    metadata: {
        title: string;
        description: string;
    }[];
}
const Accordion: FunctionComponent<AccordionProps> = ({ metadata }) => {
    const [activeIndex, setActiveIndex] = React.useState(null);

    const handleSetActiveIndex = (index: any) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="accordion">
            {[...metadata].map(({ title, description: content }, index) => (
                <AccordionItem
                    key={index}
                    item={{ title, content }}
                    isActive={index === activeIndex}
                    onSetActive={() => handleSetActiveIndex(index)}
                />
            ))}
        </div>
    );
};

export { Accordion };

import React from 'react';
import './not-found.scss';

const NotFound: React.FC = () => {
    return (
        <div className="NotFound">
            <div className="NotFound-container">
                <h1>404</h1>
                <p>La página que buscas no existe.</p>
                <a href="/" className="home-link">Volver al inicio</a>
            </div>
        </div>
    );
};

export default NotFound;
import React from 'react';
import * as images from './../../images';
import './header.scss';

function Header() {
    return (<div className='header'>
        <img src={images.Logo} alt='logo' />
    </div>);
}

export default Header;
import React from 'react';
import { NumberFormat } from '../../config/number_utils';
import './thank-you-product-item.scss';

interface ProductInfoProps {
    item: any
}
const ThankYouProductItem: React.FunctionComponent<ProductInfoProps> = ({ item }) => {
    return (
        <div className='thank-you-product-item'>
            <div className='thank-you-product-item--product-info'>
                <div className='image-container'>
                    <img src={item.photo_url} alt='photoUrl' />
                </div>
                <div className='info-container'>
                    <div>{item.brand}</div>
                    <p className='name'>{item.name}</p>
                    <span className='color'>Cantidad: {item.quantity}</span>
                </div>
                <div className='price-container'>
                    <p className="old-price line-through">
                        {NumberFormat.format(item.price)}
                    </p>
                    <p className="price">
                        {NumberFormat.format(item.price - item.discount)}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ThankYouProductItem;
import React, { FunctionComponent } from 'react';
import './carousel.scss';

interface CarouselProps {
    images: string[];
}

const Carousel: FunctionComponent<CarouselProps> = ({ images }) => {
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
    const [fade, setFade] = React.useState(false);

    const changeImage = (next: boolean) => {
        setFade(true);

        setTimeout(() => {
            setCurrentImageIndex((prevIndex) => {
                return next
                    ? (prevIndex + 1) % images.length
                    : (prevIndex - 1 + images.length) % images.length;
            });

            setFade(false);
        }, 200);
    };

    return (
        <div className="carousel">
            <div className='carousel--background' />
            <button onClick={() => changeImage(false)} className="carousel-arrow left">
                <i className="fas fa-chevron-left" />
            </button>
            <img src={images[currentImageIndex]}
                className={`carousel-image ${fade ? 'fade' : ''}`}
                alt="carousel"
            />
            <button onClick={() => changeImage(true)} className="carousel-arrow right">
                <i className="fas fa-chevron-right" />
            </button>
        </div>
    );
};

export default Carousel;

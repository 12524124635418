import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Cart from './views/cart';
import Shop from './views/shop';
import CheckOut from './views/check_out';
import ThankYou from './views/thank_you';
import Loading from './components/loading';
import { LoadingContext } from './components/loading/context';
import { LoadingProvider } from './components/loading/provider';
import './App.scss';
import NotFound from './views/not_found';

function App() {
  return (
    <LoadingProvider>
      <Router>
        <Main />
      </Router>
    </LoadingProvider >
  );
}
function Main() {
  const { isLoading } = React.useContext(LoadingContext);
  return (
    <div className='App'>
      {isLoading && <Loading />}
      <Header />
      <Routes>
        <Route path="/"  element={<Shop />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/check-out" element={<CheckOut />} />
        <Route path="/:id/thank-you" element={<ThankYou />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>

  );
}

export default App;

import React from 'react';
import { api } from '../../config/api';
import { LoadingContext } from '../../components/loading/context';
import { Accordion } from "../../components/accordion";
import ProductInfo from "../../components/product_info";
import Carousel from "../../components/carousel";
import './shop.scss';

function Shop() {
    const { openLoading, closeLoading } = React.useContext(LoadingContext);
    const [products, setProduct] = React.useState<any | null>(null);

    React.useEffect(() => {
        openLoading();
        api.get('/products').then(response => {
            setProduct(response.data.data);
        }).finally(() => {
            closeLoading();
        });
    }, []);

    return (
        <div style={{ backgroundColor: '#FFF', width: '100%', height: '100%' }}>
            {products && products?.length && products.length > 0 && <div className="shop">
                <main className="shop-container">
                    <div className="product-description-container">
                        <ProductInfo item={products[0]} />
                    </div>
                    <div className="product-image-container">
                        <Carousel images={products[0].photo_url} />
                    </div>
                    <div className="product-info-container">
                        <Accordion metadata={products[0].properties} />
                    </div>
                </main>
            </div>}
        </div>
    );
}

export default Shop;
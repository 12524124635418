import { useMemo } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface CartState {
    cart: any[];
    add: (newProduct: any) => void;
    remove: (productId: string) => void;
    reset: () => void;
}

const cartStore = create(persist<CartState>(
    (set) => ({
        cart: [],
        add: (newProduct: any) => set((state: any) => {
            const index = state.cart.findIndex((product: any) => product.reference === newProduct.reference);
            if (index !== -1) {
                let newCart = [...state.cart];
                newCart[index].quantity += 1;
                return { cart: newCart };
            } else {
                return { cart: [...state.cart, { ...newProduct, quantity: 1 }] };
            }
        }),

        remove: (productId: string) => set((state: any) => {
            const index = state.cart.findIndex((product: any) => product.reference === productId);
            if (index !== -1 && state.cart[index].quantity > 1) {
                let newCart = [...state.cart];
                newCart[index].quantity -= 1;
                return { cart: newCart };
            } else {
                return { cart: state.cart.filter((product: any) => product.reference !== productId) };
            }
        }),

        reset: () => set(() => {
            return { cart: [] };
        })
    }),
    {
        name: 'cart-storage',
        getStorage: () => localStorage,
    }
));

const useCartStore = () => {
    const { cart } = cartStore();

    const total = useMemo(() => {
        return cart.reduce((total: number, product: any) => {
            return total + product.price * product.quantity;
        }, 0);
    }, [cart]);

    const discountTotal = useMemo(() => {
        return cart.reduce((total: number, product: any) => {
            const priceAfterDiscount = product.price * (1 - (product.discount || 0));
            return total + priceAfterDiscount * product.quantity;
        }, 0);
    }, [cart]);

    const taxes = discountTotal * 0.19;

    return {
        total,
        discountTotal,
        taxes,
        total_taxes: discountTotal - taxes,
    };
};

export { cartStore, useCartStore };
import React from 'react';
import './order-summary-item.scss';
import { NumberFormat } from '../../config/number_utils';

interface OrderSummaryItemProps {
    item: any;
}

const OrderSummaryItem: React.FunctionComponent<OrderSummaryItemProps> = ({ item }) => {
    const { quantity, price, photo_url: images, discount, name, cart_property: { key, value } } = item;
    return (
        <div className='order-summary-item'>
            <div className='order-summary-item--product-info'>
                <div className='image-container'>
                    <img src={images[0]} alt='photoUrl' />
                </div>
                <div className='info-container'>
                    <p className='name'>{name}</p>
                    <span className='text'>{key}: <span>{value}</span></span>
                    <span className='text'>Cantidad: <span>{quantity}</span></span>
                </div>
                <div className='price-container'>
                    <p className="old-price line-through">
                        {NumberFormat.format(price * quantity)}
                    </p>
                    <p className="price">
                        {NumberFormat.format(price * discount * quantity)}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default OrderSummaryItem;
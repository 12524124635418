import React from 'react';
import './cart-summary.scss';
import { useCartStore } from '../../store/cart';
import { NumberFormat } from '../../config/number_utils';
import { useNavigate } from 'react-router-dom';

function CartSummary() {
    const { total, discountTotal } = useCartStore();
    let navigate = useNavigate();
    return (
        <div className='cart-summary'>
            <h2>
                Resumen de la orden
            </h2>
            <div className="flex justify-between">
                <span className='item'>
                    Subtotal:
                </span>
                <span className='item-value'>
                    {NumberFormat.format(total)}
                </span>
            </div>
            <div className="flex justify-between">
                <span className='item'>
                    Descuento:
                </span>
                <span className='item-value'>
                    {NumberFormat.format(discountTotal)}
                </span>
            </div>
            <button onClick={() => {
                navigate('/check-out');
            }}>
                Proceder al pago
            </button>
        </div>
    );
}

export default CartSummary;
import React from 'react';
import { LoadingContext } from './context';

export const LoadingProvider = ({ children }: any) => {
    const [isLoading, setLoading] = React.useState(false);

    const openLoading = () => {
        setLoading(true);
    };

    const closeLoading = () => {
        setLoading(false);
    };

    return (
        <LoadingContext.Provider value={{ isLoading, openLoading, closeLoading }}>
            {children}
        </LoadingContext.Provider>
    );
};

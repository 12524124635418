import React, { FunctionComponent } from 'react';
import './add-to-cart--button.scss';

interface AddToCartProps {
    onPressed: () => void;
}

const AddToCartButton: FunctionComponent<AddToCartProps> = ({ onPressed }) => {
    return (
        <button className={'add-to-cart'} onClick={onPressed}>
            <span>Agregar al carrito</span>
        </button>
    );
};

export { AddToCartButton };

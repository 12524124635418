import React from 'react';
import { Helmet } from 'react-helmet';
import ThankYouProductItem from '../../components/thank_you_product_item';
import { LoadingContext } from '../../components/loading/context';
import { api } from '../../config/api';
import { useParams } from 'react-router-dom';
import { NumberFormat } from '../../config/number_utils';
import './thank-you.scss';

function ThankYou() {
    const [order, setOrder] = React.useState<any>(null);
    const { openLoading, closeLoading } = React.useContext(LoadingContext);
    const { id: orderId } = useParams();

    React.useEffect(() => {
        openLoading();
        api.get(`/order/${orderId}`).then(response => {
            setOrder(response.data.data);
        }).finally(() => {
            closeLoading();
        });
    }, []);

    return (
        <div className="thank-you">
            <Helmet>
                <title>Gracias por tu Compra - Tecno Ofertas</title>
                <meta name="description" content="Gracias por comprar en Tecno Ofertas. Tu pedido ha sido recibido y está siendo procesado. Te enviaremos una actualización pronto." />
            </Helmet>
            {order && <>
                <div className='container'>
                    <img src={order.items[0].photo_url} alt='img' />
                    <h2>Gracias por tu orden!</h2>
                    {order.status !== 'CANCELED' && <p>Recibirás un correo a {order.user.email} una vez tu orden este confirmada.</p>}
                    {order.status === 'CANCELED' && <p>Lo lamentamos, no pudimos completar tu orden por un problema en tu pago.</p>}
                    <div className='status-container' style={{ backgroundColor: order.status === 'PENDING' ? '#cec30b' : order.status === 'CANCELED' ? '#c70000' : '#019557' }}>
                        {order.status !== 'CANCELED' && order.status !== 'PENDING' && <i className="fas fa-check" />}
                        {order.status === 'CANCELED' && <i className="fas fa-times" />}
                        {order.status === 'PENDING' && <i className="fas fa-clock" />}
                    </div>
                </div>
                <div className='order-detail'>
                    <h3 className="font-bold text-gray-800">Orden #{order.orderId}</h3>
                    {order.status !== 'CANCELED' && <ol className='order-status-list'>
                        <li className={`${order.status === 'ORDERED' || order.status !== 'PENDING' ? 'active' : ''}`}>
                            <span>Ordenada</span>
                        </li>
                        <li className={`${order.status === 'PROCESSING' || order.status === 'SHIPPED' ? 'active' : ''}`}>
                            <span>Procesada</span>
                        </li>
                        <li className={`${order.status === 'SHIPPED' || order.status === 'DELIVERED' ? 'active' : ''}`}>
                            <span>Enviada</span>
                        </li>
                    </ol>}
                    <div className='order-info'>
                        <div className='shipping'>
                            <h2>Dirección de envio</h2>
                            <p>{order?.address?.main_address?.toUpperCase()}</p>
                            <p>{order?.address?.optional_address?.toUpperCase()}</p>
                            <span>{`${order?.address?.state_address?.toUpperCase()}, ${order?.address?.city?.toUpperCase()}`}</span>
                            <p className='black'>{order?.address?.country}</p>
                            <p className='black'>Tel: {order?.user?.phone}</p>
                            <p className='black'>Correo: {order?.user?.email}</p>
                        </div>
                        <div className='payment-method'>
                            <h2>Método de pago</h2>
                            <p>{order.transaction.payment_method[0]} <span>{order.transaction.payment_method[1]}</span></p>
                        </div>
                        <div className='order-summary'>
                            <h2>Resumen</h2>
                            <div className="price-container">
                                <p>Articulos</p>
                                <p>{NumberFormat.format(order.amount)}</p>
                            </div>
                            <div className="price-container">
                                <p>Envío</p>
                                <p>--</p>
                            </div>
                            <div className="price-container">
                                <p>Descuento</p>
                                <p>{NumberFormat.format(order.discount)}</p>
                            </div>
                            <div className="total">
                                <p>Total</p>
                                <p>{NumberFormat.format(order.total_amount)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='products'>
                    {order.items.map((item: any) => <ThankYouProductItem key={item.reference} item={item} />)}
                </div>
            </>}

        </div>
    );
}

export default ThankYou;
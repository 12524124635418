import React, { FunctionComponent } from 'react';
import { AddToCartButton } from '../add_to_cart__button';
import { NumberFormat } from '../../config/number_utils';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { cartStore } from '../../store/cart';
import './product-info.scss';

interface ProductInfoProps {
    item: any
}

const ProductInfo: FunctionComponent<ProductInfoProps> = ({ item }) => {
    const { name, description, price, discount } = item;
    const { add } = cartStore();
    let navigate = useNavigate();

    return (
        <div className="product-info">
            <Helmet>
                <title>{name}</title>
                <meta name="description" content={description} />
            </Helmet>
            <h1 className="title">
                {name}
            </h1>
            <p className="price-discount line-through">
                {NumberFormat.format(price)}
            </p>
            <p className="price">
                {NumberFormat.format(price * discount)}
            </p>
            <p className="description">
                {description}
            </p>
            {Boolean(item.stock && item.stock > 0) && <AddToCartButton onPressed={() => {
                add(item);
                navigate('/cart');
            }} />}
        </div>
    );
};

export default ProductInfo;
